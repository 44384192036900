import axios from 'axios'
export const getIPLogin = token => axios.get(`/ipLogin?token=${token}`,) // 判断是否在ip内访问 
export const getSelectDeptList = () => axios.get('/selectDeptList') // 判断是否在ip内访问
export const postIPUserRegister = params => axios.post('/register', params) // 用户注册
export const postLogin = params => axios.post('/login', params) // 帐号登录
export const getInfo = () => axios.get('/getInfo') // 获取用户信息

export const selectUserStudyStatistics = () => axios.get('/web/statistics/selectUserStudyStatistics') // 获取用户学习统计
export const selectUserViewList = params => axios.get(`/web/userview/selectUserViewList?pageNum=${params.pageNum}&pageSize=${params.pageSize}&searchValue=${params.searchValue}`) // 获取用户观看记录
export const getUserCollectList = params => axios.get(`/web/usercollect/getUserCollectList?pageNum=${params.pageNum}&pageSize=${params.pageSize}&videoTitle=${params.videoTitle}`) // 获取用户收藏记录
export const selectAnswerRecordList = params => axios.get(`/web/useranswer/selectAnswerRecordList?pageNum=${params.pageNum}&pageSize=${params.pageSize}&title=${params.title}`) // 获取用户答题记录
export const changeinfo = params => axios.post('/web/user/changeinfo', params) // 修改头像或者用户名
export const changepwd = params => axios.post('/web/user/changepwd', params) // 修改密码

export const getCount = () => axios.get('/biz/vansweruser/count') // 判断是否在ip内访问


export const getZhengshu = () => axios.get('/biz/level/list') // 获取证书列表


export const getLevel = () => axios.get('/biz/level/getLevel') // 获取当前用户等级